@import "../themes.css";

.select-input-container {
  height: 100px;
  width: 240px;
  display: block;
  float: left;
}

.select-input-container > select {
  margin-top: 10px;
  background-color: white;
}

.select-input-container.invalid > select {
  margin-top: 10px;
  background-color: white;
  border: 1px solid red;
}
