@import "../themes.css";

.rounded-button {
  color: var(--text-color);
  position: relative;
  box-sizing: border-box;
  background-color: var(--button-color);
  border-radius: 33px;
  display: flex;
  justify-content: center; /* Horizontal centering */
  align-items: center; /* Vertical centering */
  height: 33px;
  min-width: 33px;
  padding: 16px;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
}

.rounded-button:hover {
  filter: brightness(97%);
}

.rounded-button:active {
  transform: scale(0.98);
}

.rounded-button-label {
  display: inline;
  border: none !important;
}

.rounded-button-icon {
  display: inline;
  font-size: 26px;
  position: absolute;
  border: none !important;
}

.action-outline-red {
  background-color: var(--background-color);
  border: 1px solid #e62b45;
  color: #e62b45;
}

.action-outline-black {
  background-color: var(--background-color);
  border: 1px solid black;
  color: black;
}

.action-outline-black > * {
  border: none !important;
}

.file-tag {
  background-color: black;
  color: white;
}

.action-dark {
  background-color: var(--primary-color);
  color: var(--background-color);
}
