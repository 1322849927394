/* Font weight 100 */
@font-face {
    font-family: 'TWKLausanne';
    src: url('./fonts/TWKLausanne-100.woff2') format('woff2'),
         url('./fonts/TWKLausanne-100.woff') format('woff');
    font-weight: 100;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'TWKLausanne';
    src: url('./fonts/TWKLausanne-100Italic.woff2') format('woff2'),
         url('./fonts/TWKLausanne-100Italic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
  }
  
  /* Font weight 200 */
  @font-face {
    font-family: 'TWKLausanne';
    src: url('./fonts/TWKLausanne-200.woff2') format('woff2'),
         url('./fonts/TWKLausanne-200.woff') format('woff');
    font-weight: 200;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'TWKLausanne';
    src: url('./fonts/TWKLausanne-200Italic.woff2') format('woff2'),
         url('./fonts/TWKLausanne-200Italic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
  }
  
  /* Font weight 300 */
  @font-face {
    font-family: 'TWKLausanne';
    src: url('./fonts/TWKLausanne-300.woff2') format('woff2'),
         url('./fonts/TWKLausanne-300.woff') format('woff');
    font-weight: 300;
    font-style: normal;
  }
  
  /* Font weight 400 */
  @font-face {
    font-family: 'TWKLausanne';
    src: url('./fonts/TWKLausanne-400.woff2') format('woff2'),
         url('./fonts/TWKLausanne-400.woff') format('woff');
    font-weight: 400;
    font-style: normal;
  }
  
  /* Font weight 500 */
  @font-face {
    font-family: 'TWKLausanne';
    src: url('./fonts/TWKLausanne-500.woff2') format('woff2'),
         url('./fonts/TWKLausanne-500.woff') format('woff');
    font-weight: 500;
    font-style: normal;
  }
  
  /* Font weight 600 */
  @font-face {
    font-family: 'TWKLausanne';
    src: url('./fonts/TWKLausanne-600.woff2') format('woff2'),
         url('./fonts/TWKLausanne-600.woff') format('woff');
    font-weight: 600;
    font-style: normal;
  }
  
  /* Font weight 700 */
  @font-face {
    font-family: 'TWKLausanne';
    src: url('./fonts/TWKLausanne-700.woff2') format('woff2'),
         url('./fonts/TWKLausanne-700.woff') format('woff');
    font-weight: 700;
    font-style: normal;
  }
  
  /* Font weight 800 */
  @font-face {
    font-family: 'TWKLausanne';
    src: url('./fonts/TWKLausanne-800.woff2') format('woff2'),
         url('./fonts/TWKLausanne-800.woff') format('woff');
    font-weight: 800;
    font-style: normal;
  }
  
  /* Font weight 900 */
  @font-face {
    font-family: 'TWKLausanne';
    src: url('./fonts/TWKLausanne-900.woff2') format('woff2'),
         url('./fonts/TWKLausanne-900.woff') format('woff');
    font-weight: 900;
    font-style: normal;
  }
  
  /* Font weight 1000 */
  @font-face {
    font-family: 'TWKLausanne';
    src: url('./fonts/TWKLausanne-1000.woff2') format('woff2'),
         url('./fonts/TWKLausanne-1000.woff') format('woff');
    font-weight: 1000;
    font-style: normal;
  }