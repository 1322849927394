@import "./themes.css";

:root {
  --sidebar-width: 280px;
  --content-width: calc(100% - var(--sidebar-width));
}
.rdrDefinedRangesWrapper {
  display: none !important;
}
body {
  margin: 0;
  padding: 0;
  font-family: "TWKLausanne";
  font-size: 14px;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--primary-color);
}

table {
  border: 1px solid var(--border-color);
  border-radius: 5px;
  border-spacing: 0px;
  table-layout: fixed;
  width: auto;
  overflow-x: scroll;
}
tbody {
  border-bottom: 1px solid var(--border-color);
}

th {
  border-bottom: 1px solid var(--border-color);
  padding: 14px;
  text-align: left;
  outline: 0;
  margin: 0;
  font-weight: 400;
  font-size: 12px;
  color: #00000066;
  cursor: pointer;
}
th:active {
  background-color: #0000002a;
}

table th + th {
  border-left: 0.5px solid var(--border-color-light);
}

td {
  padding: 4px;
  padding-left: 14px;
  outline: 0;
  border-bottom: 0.5px solid var(--border-color-light);
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

tr:hover {
  background-color: var(--border-color-light);
}

.tr-expanded {
  background-color: var(--border-color-light);
}

.tr-expanded-content {
  background-color: var(--border-color-light);
  position: relative;
  text-align: left;
  white-space: nowrap; /* Prevent text from wrapping by default */
  overflow-x: auto; /* Enable horizontal scrolling */
  max-width: 200px; /* Set a maximum width if needed */
  word-wrap: break-word; /* Allow long words to break and wrap onto the next line */
}
.tr-expanded-content ::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 850px;
  right: 0;
  top: 0;
  background-color: var(--border-color-light);
}
tr:active {
  background-color: transparent;
}

select {
  border: 1px solid var(--border-color);
  border-radius: 6px;
  border-spacing: 0px;
  padding: 14px;
  margin-right: 14px;
  padding-left: 8px;
  margin-bottom: 8px;
  outline: none;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none; /* Remove the default arrow for WebKit browsers */
  -moz-appearance: none; /* Remove the default arrow for Firefox */
  background: url("./img/select.png") no-repeat; /* Use a custom arrow icon */
  background-position: right center; /* Adjust the position of the icon */
  text-align: left;
  background-size: 22px 18px;
  min-width: 230px;
}
select option {
  color: black !important;
}
textarea {
  border: 1px solid var(--border-color);
  border-radius: 6px;
  border-spacing: 0px;
  padding: 14px;
  width: 100%;
  min-width: 100%;
  font-family: "TWKLausanne";
  max-width: 100%;
  min-height: 180px;
  box-sizing: border-box;
}

input[type="text"],
input[type="number"],
input[type="select"] {
  border: 1px solid var(--border-color);
  padding: 8px;
  font-family: "TWKLausanne";
  box-sizing: border-box;
  display: inline-block;
  border-radius: 6px;
  border-spacing: 0px;
  min-width: 230px;
  padding: 14px;
}

a {
  color: var(--primary-color);
}
