@import "../themes.css";

.text-input-container {
  height: 100px;
  width: 240px;
  display: block;
  float: left;
}

.text-input-container > input {
  margin-top: 10px;
  background-color: white;
}

.text-input-container > small {
  color: red;
  font-size: 9px;
}

.invalid > input {
  border: 1px solid red !important;
  border-radius: 6px;
}

.disabled {
  opacity: 0.5;
}
